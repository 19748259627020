import React, { useState, useEffect } from "react";
import "./App.css";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Stack, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
function App() {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    // ComponentDidMount benzeri davranış için useEffect kullanıyoruz
    const timer = setTimeout(() => {
      setShowText(true); // 3 saniye sonra metni göster
    }, 1500);

    // Temizleme fonksiyonu: bileşen unmount olduğunda zamanlayıcıyı temizle
    return () => clearTimeout(timer);
  }, []); // [] içindeki boş dependency array sadece bir kere çalıştırılmasını sağlar

  return (
    <div className="App" style={{ cursor: "default" }}>
      <header className="App-header">
        <div className={`coming-soon ${showText ? "show" : ""}`}>
          <h1>Web Sitemizi Sizler İçin Hazırlıyoruz...</h1>
          <p>Şu anda mağazamız sadece fiziksel olarak hizmet vermektedir.</p>
          <Stack spacing={2}>
            <Typography
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
              variant="body"
            >
              <FmdGoodIcon />
              10 Mart Caddesi Şehit Mürsel Basa Sokak No:15/B Pazar/Rize
            </Typography>
            <Typography
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
              variant="body"
            >
              <LocalPhoneIcon /> 0(464) 612 70 25
            </Typography>
          </Stack>
        </div>
      </header>
    </div>
  );
}

export default App;
